import React from "react";
import Header from "../partials/header"
import Footer from "../partials/footer"
import {StaticImage} from "gatsby-plugin-image";
import borderheadling from '../images/headlineborder.png'
import AruzySeo from "../components/aruzySeo";
import HeaderTitle from "../components/HeaderTitle";

const Aboutus = () => {
    return (
        <main>
            <Header/>
            <AruzySeo title={"About Us"}/>
            <div className="App topattachbgsection">
                <div className="container  changeheading pt-3">
                    <div className="row justify-content-center">
                        <div className="col-md-12">
                            <div className="text-center">
                                <HeaderTitle title={'ABOUT ARUZY'}/>
                            </div>
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-7 ">
                                        <div className="aboutusbluebg">
                                            <h4>Be part of it</h4>
                                            <p> ARUZY are a member’s only lifestyle brand for people who know how to live.</p>
                                            <h3 className="w-fit-content position-relative">
                                                Looking for fun days out?<img className="borderheadling" src={borderheadling}/>
                                            </h3>
                                            <p> We want to change the way you find unforgettable
                                                experiences.

                                                ARUZY act as a matchmaker for people and brands. We
                                                are
                                                a community
                                                of sociable people who love luxurious experiences,
                                                dining offers and
                                                activities. With a monthly subscription fee, you get
                                                access to
                                                discount codes across premium brands to help you
                                                live
                                                your best
                                                life.

                                                Once you’ve signed up, a simple search on our
                                                website
                                                will help you
                                                to find the experience you’re looking for. And if
                                                you
                                                need a little
                                                help deciding, we’re here with the right tips and
                                                advice. Our option
                                                to buy bundles for full days out means you can tick
                                                every box from
                                                AM to PM and create an entire adventure.
                                            </p>
                                        </div>
                                    </div>
                                    <div className="col-md-5 shoppinggirl">
                                        <StaticImage src="../images/newimage/girlshopping.png" alt=""/>
                                    </div>
                                </div>
                            </div>
                            <div className="ourpartner mt-5">
                                <h3 className="w-fit-content position-relative">
                                    Our partners<img className="borderheadling" src={borderheadling}/>
                                </h3>
                                <p> Our partners all offer discounts to entice you to try them, but
                                    this
                                    isn’t about being cheap. It’s about finding the right thing at
                                    the
                                    best price. Sometimes that’ll be from big brands that you know,
                                    but
                                    we also go the extra mile to find those hidden gems.

                                    Whatever the offer and wherever it is, we focus on one thing.
                                    Giving
                                    you an unforgettable day. From relaxing at a spa to adventure
                                    days
                                    out, you can count on a unique experience, tailored to you, and
                                    amazing.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="App topattachbgsection">
                <div className="container  changeheading pb-5 pt-3  ">
                    <div className="row justify-content-center">
                        <div className="col-md-4">
                            <StaticImage src="../images/newimage/boygirl.png" alt=""/>
                        </div>
                        <div className="col-md-8 diningpart ">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="text-center">
                                        <StaticImage src="../images/newimage/plate.png" alt=""/>
                                        <h3>Dining offers</h3>
                                    </div>
                                    <p> Partnering with the best of the northwest and beyond, our
                                        dining
                                        offers to give you an added incentive to get out and find
                                        new
                                        favourite eateries. We like to stay ahead of the pack with
                                        new
                                        launches and popups, so you can count on being in front of
                                        the queue
                                        at the latest, happening places in your local area.
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <div className="text-center">
                                        <StaticImage src="../images/newimage/advent.png" alt=""/>
                                        <h3>Adventures</h3>
                                    </div>
                                    <p> Adult days out to suit all whims. We work with businesses to
                                        bring
                                        the best experiences for less. If you want to go high
                                        octane, we
                                        have adventure days out and fast-paced activities. If you
                                        need to
                                        take some time out, we have indulgent spa days and laidback
                                        jaunts.
                                        And if you need something in between these energies, well,
                                        we have
                                        that too.
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <div className="text-center">
                                        <StaticImage src="../images/newimage/girl.png" alt=""/>
                                        <h3>Services</h3>
                                    </div>
                                    <p> Complement your lifestyle and perfect special days out with
                                        our
                                        partners across all services. From boutique hotels to rest
                                        your head
                                        after an ARUZY experience, to meal prep services, letterbox
                                        flowers,
                                        mobile PTs and sports therapy. Because living life to the
                                        full isn’t
                                        reserved for special occasions and weekends only.
                                    </p>
                                </div>
                                <div className="col-md-6">
                                    <div className="text-center">
                                        <StaticImage src="../images/newimage/giftbox.png" alt=""/>
                                        <h3>Bundles</h3>
                                    </div>
                                    <p> Found the perfect day trip but want to complete the
                                        itinerary? Our
                                        bundles give you all the inspiration you need. Package up
                                        your
                                        activity with partner restaurants to complete your day or
                                        night and
                                        get lovely ARUZY only deals across the entire thing.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </main>
    );
};

export default Aboutus;